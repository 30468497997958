
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { Watch } from 'vue-property-decorator'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import logging from '@/utils/logging'

@Options({
  components: {},
  directives: { maska },
})
export default class WorkspaceBoard extends mixins(WorkspaceMixin) {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  @Watch('workspaceId', { immediate: true })
  async workspaceIdChanged() {
    if (this.workspaceId) {
      await this.getWorkspace(this.workspaceId)
    }
  }

  @Watch('isAuth', { immediate: true })
  async isAuthChanged() {
    if (!this.isAuth) {
      return
    }

    await this.getWorkspaces()

    this.checkAndSwitchWorkspace()
  }

  beforeMount() {
    logging.debugRender(WorkspaceBoard.name)
  }
}
